import { extendTheme } from "@chakra-ui/react";

const theme = {
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
    cssVarPrefix: "aether",
  },
  breakpoints: {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
    mobile: "600px",
  },
  fonts: {
    heading: `'Metropolis', sans-serif`,
    body: `'Metropolis', sans-serif`,
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "#222222",
        color: "white",
        _selection: {
          color: "white",
          background: "#21dec2",
        },
      },
      // styles for the `a`
      //   a: {
      //     color: "teal.500",
      //     _hover: {
      //       textDecoration: "underline",
      //     },
      //   },
    },
  },
};

export default extendTheme(theme);
